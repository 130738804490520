export const countries = [
    {
        "timezones": [
            "America/Aruba"
        ], 
        "latlng": [
            12.510052, 
            -70.009354
        ], 
        "name": "Aruba", 
        "capital": "Oranjestad"
    }, 
    {
        "timezones": [
            "Asia/Kabul"
        ], 
        "latlng": [
            34.543896, 
            69.160652
        ], 
        "name": "Afghanistan", 
        "capital": "Kabul"
    }, 
    {
        "timezones": [
            "Africa/Luanda"
        ], 
        "latlng": [
            -8.838333, 
            13.234444
        ], 
        "name": "Angola", 
        "capital": "Luanda"
    }, 
    {
        "timezones": [
            "America/Anguilla"
        ], 
        "latlng": [
            18.227230, 
            -63.048988
        ], 
        "name": "Anguilla", 
        "capital": "The Valley"
    }, 
    {
        "timezones": [
            "Europe/Mariehamn"
        ], 
        "latlng": [
            60.116667, 
            19.9
        ], 
        "name": "\u00c5land Islands", 
        "capital": "Mariehamn"
    }, 
    {
        "timezones": [
            "Europe/Tirane"
        ], 
        "latlng": [
            41.327953, 
            19.819025
        ], 
        "name": "Albania", 
        "capital": "Tirana"
    }, 
    {
        "timezones": [
            "Europe/Andorra"
        ], 
        "latlng": [
            42.506317, 
            1.521835
        ], 
        "name": "Andorra", 
        "capital": "Andorra la Vella"
    }, 
    {
        "timezones": [
            "Asia/Dubai"
        ], 
        "latlng": [
            24, 
            54
        ], 
        "name": "United Arab Emirates", 
        "capital": "Abu Dhabi"
    }, 
    {
        "timezones": [
            "America/Argentina/Buenos_Aires"
        ], 
        "latlng": [
            -34.603722, 
            -58.381592
        ], 
        "name": "Argentina", 
        "capital": "Buenos Aires"
    }, 
    {
        "timezones": [
            "America/Argentina/Cordoba"
        ], 
        "latlng": [
            -31.416668, 
            -64.183334
        ], 
        "name": "Argentina", 
        "capital": "Buenos Aires"
    }, 
    {
        "timezones": [
            "America/Argentina/Salta"
        ], 
        "latlng": [
            -24.782932, 
            -65.412155
        ], 
        "name": "Argentina", 
        "capital": "Buenos Aires"
    }, 
    {
        "timezones": [
            "America/Argentina/Jujuy"
        ], 
        "latlng": [
            -24.184340, 
            -65.302177
        ], 
        "name": "Argentina", 
        "capital": "Buenos Aires"
    }, 
    {
        "timezones": [
            "America/Argentina/Tucuman"
        ], 
        "latlng": [
            -26.808285, 
            -65.217590
        ], 
        "name": "Argentina", 
        "capital": "Buenos Aires"
    }, 
    {
        "timezones": [
            "America/Argentina/Catamarca"
        ], 
        "latlng": [
            -28.4666667, 
            -65.7833333
        ], 
        "name": "Argentina", 
        "capital": "Buenos Aires"
    }, 
    {
        "timezones": [
            "America/Argentina/La_Rioja"
        ], 
        "latlng": [
            -29.4333333, 
            -66.85
        ], 
        "name": "Argentina", 
        "capital": "Buenos Aires"
    }, 
    {
        "timezones": [
            "America/Argentina/Mendoza"
        ], 
        "latlng": [
            -32.888355, 
            -68.838844
        ], 
        "name": "Argentina", 
        "capital": "Buenos Aires"
    }, 
    {
        "timezones": [
            "America/Argentina/Rio_Gallegos"
        ], 
        "latlng": [
            -51.6333333, 
            -69.2166667
        ], 
        "name": "Argentina", 
        "capital": "Buenos Aires"
    }, 
    {
        "timezones": [
            "America/Argentina/Ushuaia"
        ], 
        "latlng": [
            -54.8, 
            -68.3
        ], 
        "name": "Argentina", 
        "capital": "Buenos Aires"
    },
    {
        "timezones": [
            "America/Argentina/San_Luis"
        ], 
        "latlng": [
            -33.3, 
            -66.35
        ], 
        "name": "Argentina", 
        "capital": "Buenos Aires"
    },
    {
        "timezones": [
            "America/Argentina/San_Juan"
        ], 
        "latlng": [
            -31.5375, 
            -68.5363889
        ], 
        "name": "Argentina", 
        "capital": "Buenos Aires"
    },
    {
        "timezones": [
            "Asia/Yerevan"
        ], 
        "latlng": [
            40.177200, 
            44.503490
        ], 
        "name": "Armenia", 
        "capital": "Yerevan"
    }, 
    {
        "timezones": [
            "Pacific/Pago_Pago"
        ], 
        "latlng": [
            -14.275632, 
            -170.702042
        ], 
        "name": "American Samoa", 
        "capital": "Pago Pago"
    }, 
    {
        "timezones": [
            "Antarctica/McMurdo"
        ], 
        "latlng": [
            -77.8499966,
            166.666664
        ], 
        "name": "Antarctica", 
        "capital": null
    }, 
    {
        "timezones": [
            "Antarctica/Casey"
        ], 
        "latlng": [
            -66.275665564,
            110.521331248
        ], 
        "name": "Antarctica", 
        "capital": null
    }, 
    {
        "timezones": [
            "Antarctica/Davis"
        ], 
        "latlng": [
            -68.57249771,
            77.967996128
        ], 
        "name": "Antarctica", 
        "capital": null
    }, 
    {
        "timezones": [ 
            "Antarctica/DumontDUrville"
        ], 
        "latlng": [
            -66.666664,
            140.0166666
        ], 
        "name": "Antarctica", 
        "capital": null
    }, 
    {
        "timezones": [
            "Antarctica/Mawson"
        ], 
        "latlng": [
            -67.5999976,
            62.8666632
        ], 
        "name": "Antarctica", 
        "capital": null
    }, 
    {
        "timezones": [
            "Antarctica/Palmer"
        ], 
        "latlng": [
            -64.767, 
            -64.05
        ], 
        "name": "Antarctica", 
        "capital": null
    }, 
    {
        "timezones": [
            "Antarctica/Rothera"
        ], 
        "latlng": [
            -67.5666644,
            -68.1333328
        ], 
        "name": "Antarctica", 
        "capital": null
    }, 
    {
        "timezones": [ 
            "Antarctica/Syowa"
        ], 
        "latlng": [
            -69.0,
            39.583331
        ], 
        "name": "Antarctica", 
        "capital": null
    }, 
    {
        "timezones": [
            "Antarctica/Troll"
        ], 
        "latlng": [
            -72.0166666,
            2.5333312
        ], 
        "name": "Antarctica", 
        "capital": null
    }, 
    {
        "timezones": [
            "Antarctica/Vostok"
        ], 
        "latlng": [
            -77.500000, 
            106.000000
        ], 
        "name": "Antarctica", 
        "capital": null
    }, 
    {
        "timezones": [
            "Indian/Kerguelen"
        ], 
        "latlng": [
            -49.3499986,
            70.2166658
        ], 
        "name": "French Southern and Antarctic Lands", 
        "capital": "Port-aux-Fran\u00e7ais"
    }, 
    {
        "timezones": [
            "America/Antigua"
        ], 
        "latlng": [
            17.087635, 
            -61.772346
        ], 
        "name": "Antigua and Barbuda", 
        "capital": "Saint John's"
    }, 
    {
        "timezones": [
            "Australia/Lord_Howe"
        ], 
        "latlng": [
            -31.5499978,
            159.083333
        ], 
        "name": "Australia", 
        "capital": "Canberra"
    }, 
    {
        "timezones": [
            "Antarctica/Macquarie"
        ], 
        "latlng": [
            -54.499998,
            158.9499962
        ], 
        "name": "Australia", 
        "capital": "Canberra"
    }, 
    {
        "timezones": [ 
            "Australia/Hobart"
        ], 
        "latlng": [
            -42.880554, 
            147.324997
        ], 
        "name": "Australia", 
        "capital": "Canberra"
    }, 
    {
        "timezones": [
            "Australia/Currie"
        ], 
        "latlng": [
            -39.93333, 
            143.86666
        ], 
        "name": "Australia", 
        "capital": "Canberra"
    }, 
    {
        "timezones": [
            "Australia/Melbourne"
        ], 
        "latlng": [
            -37.840935, 
            144.946457
        ], 
        "name": "Australia", 
        "capital": "Canberra"
    }, 
    {
        "timezones": [
            "Australia/Sydney"
        ], 
        "latlng": [
            -33.865143, 
            151.209900
        ], 
        "name": "Australia", 
        "capital": "Canberra"
    }, 
    {
        "timezones": [
            "Australia/Broken_Hill"
        ], 
        "latlng": [
            -31.956667, 
            141.467773
        ], 
        "name": "Australia", 
        "capital": "Canberra"
    }, 
    {
        "timezones": [ 
            "Australia/Brisbane"
        ], 
        "latlng": [
            -27.470125, 
            153.021072
        ], 
        "name": "Australia", 
        "capital": "Canberra"
    }, 
    {
        "timezones": [
            "Australia/Lindeman"
        ], 
        "latlng": [
            -20.4499982,
            149.0333332
        ], 
        "name": "Australia", 
        "capital": "Canberra"
    }, 
    {
        "timezones": [
            "Australia/Adelaide"
        ], 
        "latlng": [
            -34.921230, 
            138.599503

        ], 
        "name": "Australia", 
        "capital": "Canberra"
    }, 
    {
        "timezones": [
            "Australia/Darwin"
        ], 
        "latlng": [
            -12.462827, 
            130.841782
        ], 
        "name": "Australia", 
        "capital": "Canberra"
    }, 
    {
        "timezones": [ 
            "Australia/Perth"
        ], 
        "latlng": [
            -31.953512, 
            115.857048
        ], 
        "name": "Australia", 
        "capital": "Canberra"
    }, 
    {
        "timezones": [
            "Australia/Eucla"
        ], 
        "latlng": [
            -31.675,
            128.883
        ], 
        "name": "Australia", 
        "capital": "Canberra"
    }, 
    {
        "timezones": [
            "Europe/Vienna"
        ], 
        "latlng": [
            48.20849,
            16.37208
        ], 
        "name": "Austria", 
        "capital": "Vienna"
    }, 
    {
        "timezones": [
            "Asia/Baku"
        ], 
        "latlng": [
            40.409262, 
            49.867092
        ], 
        "name": "Azerbaijan", 
        "capital": "Baku"
    }, 
    {
        "timezones": [
            "Africa/Bujumbura"
        ], 
        "latlng": [
            -3.3896077,
            29.9255829
        ], 
        "name": "Burundi", 
        "capital": "Bujumbura"
    }, 
    {
        "timezones": [
            "Europe/Brussels"
        ], 
        "latlng": [
            50.85045,
            4.34878
        ], 
        "name": "Belgium", 
        "capital": "Brussels"
    }, 
    {
        "timezones": [
            "Africa/Porto-Novo"
        ], 
        "latlng": [
            6.4973,
            2.6051
        ], 
        "name": "Benin", 
        "capital": "Porto-Novo"
    }, 
    {
        "timezones": [
            "Africa/Ouagadougou"
        ], 
        "latlng": [
            12.371428, 
            -1.519660
        ], 
        "name": "Burkina Faso", 
        "capital": "Ouagadougou"
    }, 
    {
        "timezones": [
            "Asia/Dhaka"
        ], 
        "latlng": [
            23.777176, 
            90.399452
        ], 
        "name": "Bangladesh", 
        "capital": "Dhaka"
    }, 
    {
        "timezones": [
            "Europe/Sofia"
        ], 
        "latlng": [
            42.6977082, 
            23.321867500000053
        ], 
        "name": "Bulgaria", 
        "capital": "Sofia"
    }, 
    {
        "timezones": [
            "Asia/Bahrain"
        ], 
        "latlng": [
            26.25033, 
            50.65006
        ], 
        "name": "Bahrain", 
        "capital": "Manama"
    }, 
    {
        "timezones": [
            "America/Nassau"
        ], 
        "latlng": [
            25.05823,
            -77.34306
        ], 
        "name": "Bahamas", 
        "capital": "Nassau"
    }, 
    {
        "timezones": [
            "Europe/Sarajevo"
        ], 
        "latlng": [
            43.8583,
            18.4272
        ], 
        "name": "Bosnia and Herzegovina", 
        "capital": "Sarajevo"
    }, 
    {
        "timezones": [
            "America/St_Barthelemy"
        ], 
        "latlng": [
            17.89618,
            -62.84978
        ], 
        "name": "Saint Barth\u00e9lemy", 
        "capital": "Gustavia"
    }, 
    {
        "timezones": [
            "Europe/Minsk"
        ], 
        "latlng": [
            53.893009, 
            27.567444
        ], 
        "name": "Belarus", 
        "capital": "Minsk"
    }, 
    {
        "timezones": [
            "America/Belize"
        ], 
        "latlng": [
            17.1907805,
            -88.3596575
        ], 
        "name": "Belize", 
        "capital": "Belmopan"
    }, 
    {
        "timezones": [
            "Atlantic/Bermuda"
        ], 
        "latlng": [
            32.3191775,
            -64.7670827
        ], 
        "name": "Bermuda", 
        "capital": "Hamilton"
    }, 
    {
        "timezones": [
            "America/La_Paz"
        ], 
        "latlng": [
            -16.489689, 
            -68.119293
        ], 
        "name": "Bolivia", 
        "capital": "Sucre"
    }, 
    {
        "timezones": [
            "America/Noronha"
        ], 
        "latlng": [
            -3.85, 
            -32.41666
        ], 
        "name": "Brazil", 
        "capital": "Bras\u00edlia"
    }, 
    {
        "timezones": [
            "America/Belem"
        ], 
        "latlng": [
            -1.455833, 
            -48.503887
        ], 
        "name": "Brazil", 
        "capital": "Bras\u00edlia"
    }, 
    {
        "timezones": [
            "America/Fortaleza"
        ], 
        "latlng": [
            -3.731903, 
            -38.526739
        ], 
        "name": "Brazil", 
        "capital": "Bras\u00edlia"
    }, 
    {
        "timezones": [
            "America/Recife"
        ], 
        "latlng": [
            -8.047562, 
            -34.876964
        ], 
        "name": "Brazil", 
        "capital": "Bras\u00edlia"
    }, 
    {
        "timezones": [
            "America/Araguaina"
        ], 
        "latlng": [
            -7.192773, 
            -48.204827
        ], 
        "name": "Brazil", 
        "capital": "Bras\u00edlia"
    }, 
    {
        "timezones": [
            "America/Maceio"
        ], 
        "latlng": [
            -9.648139,
            -35.717239
        ], 
        "name": "Brazil", 
        "capital": "Bras\u00edlia"
    }, 
    {
        "timezones": [
            "America/Bahia"
        ], 
        "latlng": [
            -12.974722, 
            -38.476665
        ], 
        "name": "Brazil", 
        "capital": "Bras\u00edlia"
    }, 
    {
        "timezones": [
            "America/Sao_Paulo"
        ], 
        "latlng": [
            -23.533773, 
            -46.625290
        ], 
        "name": "Brazil", 
        "capital": "Bras\u00edlia"
    }, 
    {
        "timezones": [
            "America/Campo_Grande"
        ], 
        "latlng": [
            -20.4697105, 
            -54.620121100000006
        ], 
        "name": "Brazil", 
        "capital": "Bras\u00edlia"
    }, 
    {
        "timezones": [
            "America/Cuiaba"
        ], 
        "latlng": [
            -15.59611, 
            -56.09667
        ], 
        "name": "Brazil", 
        "capital": "Bras\u00edlia"
    }, 
    {
        "timezones": [
            "America/Santarem"
        ], 
        "latlng": [
            -2.450629, 
            -54.700923
        ], 
        "name": "Brazil", 
        "capital": "Bras\u00edlia"
    }, 
    {
        "timezones": [
            "America/Porto_Velho"
        ], 
        "latlng": [
            -8.76194, 
            -63.90389
        ], 
        "name": "Brazil", 
        "capital": "Bras\u00edlia"
    }, 
    {
        "timezones": [
            "America/Boa_Vista"
        ], 
        "latlng": [
            2.81972, 
            -60.67333
        ], 
        "name": "Brazil", 
        "capital": "Bras\u00edlia"
    }, 
    {
        "timezones": [
            "America/Manaus"
        ], 
        "latlng": [
            -3.117034, 
            -60.025780
        ], 
        "name": "Brazil", 
        "capital": "Bras\u00edlia"
    }, 
    {
        "timezones": [
            "America/Eirunepe"
        ], 
        "latlng": [
            -6.6666667, 
            -69.8666667
        ], 
        "name": "Brazil", 
        "capital": "Bras\u00edlia"
    }, 
    {
        "timezones": [
            "America/Rio_Branco"
        ], 
        "latlng": [
            -9.97472, 
            -67.81
        ], 
        "name": "Brazil", 
        "capital": "Bras\u00edlia"
    }, 
    {
        "timezones": [
            "America/Barbados"
        ], 
        "latlng": [
            13.103562, 
            -59.603226
        ], 
        "name": "Barbados", 
        "capital": "Bridgetown"
    }, 
    {
        "timezones": [
            "Asia/Brunei"
        ], 
        "latlng": [
            4.5242486,
            114.7196266
        ], 
        "name": "Brunei", 
        "capital": "Bandar Seri Begawan"
    }, 
    {
        "timezones": [
            "Asia/Thimphu"
        ], 
        "latlng": [
            27.4666666667, 
            89.6416666667
        ], 
        "name": "Bhutan", 
        "capital": "Thimphu"
    }, 
    {
        "timezones": [
            "Europe/Oslo"
        ],
        "latlng": [
            59.911491,
            10.757933
        ], 
        "name": "Norway", 
        "capital": "Oslo"
    }, 
    {
        "timezones": [
            "Africa/Gaborone"
        ], 
        "latlng": [
            -24.653257, 
            25.906792
        ], 
        "name": "Botswana", 
        "capital": "Gaborone"
    }, 
    {
        "timezones": [
            "Africa/Bangui"
        ], 
        "latlng": [
            4.36122, 
            18.55496
        ], 
        "name": "Central African Republic", 
        "capital": "Bangui"
    }, 
    {
        "timezones": [
            "America/St_Johns"
        ], 
        "latlng": [
            47.560539, 
            -52.712830
        ], 
        "name": "Canada", 
        "capital": "Ottawa"
    }, 
    {
        "timezones": [
            "America/Halifax"
        ], 
        "latlng": [
            44.651070, 
            -63.582687
        ], 
        "name": "Canada", 
        "capital": "Ottawa"
    }, 
    {
        "timezones": [ 
            "America/Glace_Bay"
        ], 
        "latlng": [
            46.196919, 
            -59.957004
        ], 
        "name": "Canada", 
        "capital": "Ottawa"
    }, 
    {
        "timezones": [
            "America/Moncton"
        ], 
        "latlng": [
            46.08782, 
            -64.77823
        ], 
        "name": "Canada", 
        "capital": "Ottawa"
    }, 
    {
        "timezones": [
            "America/Goose_Bay"
        ], 
        "latlng": [
            53.33333, 
            -60.41666
        ], 
        "name": "Canada", 
        "capital": "Ottawa"
    }, 
    {
        "timezones": [
            "America/Blanc-Sablon"
        ], 
        "latlng": [
            51.4264451, 
            -57.131314799999984
        ], 
        "name": "Canada", 
        "capital": "Ottawa"
    }, 
    {
        "timezones": [
            "America/Toronto"
        ], 
        "latlng": [
            43.651070, 
            -79.347015
        ], 
        "name": "Canada", 
        "capital": "Ottawa"
    }, 
    {
        "timezones": [
            "America/Nipigon"
        ], 
        "latlng": [
            49.01666, 
            -88.26666
        ], 
        "name": "Canada", 
        "capital": "Ottawa"
    }, 
    {
        "timezones": [
            "America/Thunder_Bay"
        ], 
        "latlng": [
            48.382221, 
            -89.246109
        ], 
        "name": "Canada", 
        "capital": "Ottawa"
    }, 
    {
        "timezones": [
            "America/Iqaluit"
        ], 
        "latlng": [
            63.748611,
            -68.519722
        ], 
        "name": "Canada", 
        "capital": "Ottawa"
    }, 
    {
        "timezones": [
            "America/Pangnirtung"
        ], 
        "latlng": [
            66.133333, 
            -65.75
        ], 
        "name": "Canada", 
        "capital": "Ottawa"
    }, 
    {
        "timezones": [ 
            "America/Atikokan"
        ], 
        "latlng": [
            48.75861, 
            -91.62166
        ], 
        "name": "Canada", 
        "capital": "Ottawa"
    }, 
    {
        "timezones": [ 
            "America/Winnipeg"
        ], 
        "latlng": [
            49.895077, 
            -97.138451
        ], 
        "name": "Canada", 
        "capital": "Ottawa"
    }, 
    {
        "timezones": [  
            "America/Rainy_River"
        ], 
        "latlng": [
            48.71666, 
            -94.56666
        ], 
        "name": "Canada", 
        "capital": "Ottawa"
    }, 
    {
        "timezones": [ 
            "America/Resolute"
        ], 
        "latlng": [
            74.697299, 
            -94.829729
        ], 
        "name": "Canada", 
        "capital": "Ottawa"
    }, 
    {
        "timezones": [
            "America/Rankin_Inlet"
        ], 
        "latlng": [
            62.8097222222, 
            -92.0994444444
        ], 
        "name": "Canada", 
        "capital": "Ottawa"
    }, 
    {
        "timezones": [  
            "America/Regina"
        ], 
        "latlng": [
            50.445210, 
            -104.618896
        ], 
        "name": "Canada", 
        "capital": "Ottawa"
    }, 
    {
        "timezones": [  
            "America/Swift_Current"
        ], 
        "latlng": [
            50.28337, 
            -107.80135
        ], 
        "name": "Canada", 
        "capital": "Ottawa"
    },
    {
        "timezones": [
            "America/Edmonton"
        ], 
        "latlng": [
            53.631611, 
            -113.323975
        ], 
        "name": "Canada", 
        "capital": "Ottawa"
    },
    {
        "timezones": [ 
            "America/Cambridge_Bay"
        ], 
        "latlng": [
            42.373611, 
            -71.110558
        ], 
        "name": "Canada", 
        "capital": "Ottawa"
    },
    {
        "timezones": [
            "America/Yellowknife"
        ], 
        "latlng": [
            62.453972, 
            -114.371788
        ], 
        "name": "Canada", 
        "capital": "Ottawa"
    },
    {
        "timezones": [
            "America/Inuvik"
        ], 
        "latlng": [
            68.3607437, 
            -133.7230177
        ], 
        "name": "Canada", 
        "capital": "Ottawa"
    },
    {
        "timezones": [ 
            "America/Creston"
        ], 
        "latlng": [
            41.058601, 
            -94.361343
        ], 
        "name": "Canada", 
        "capital": "Ottawa"
    },
    {
        "timezones": [
            "America/Dawson_Creek"
        ], 
        "latlng": [
            55.75984, 
            -120.2403
        ], 
        "name": "Canada", 
        "capital": "Ottawa"
    },
    {
        "timezones": [ 
            "America/Fort_Nelson"
        ], 
        "latlng": [
            58.8050174, 
            -122.69723599999998
        ], 
        "name": "Canada", 
        "capital": "Ottawa"
    },
    {
        "timezones": [ 
            "America/Vancouver"
        ], 
        "latlng": [
            49.246292, 
            -123.116226
        ], 
        "name": "Canada", 
        "capital": "Ottawa"
    },
    {
        "timezones": [ 
            "America/Whitehorse"
        ], 
        "latlng": [
            60.721188, 
            -135.056839
        ], 
        "name": "Canada", 
        "capital": "Ottawa"
    },
    {
        "timezones": [ 
            "America/Dawson"
        ], 
        "latlng": [
            46.867565, 
            -99.751389
        ], 
        "name": "Canada", 
        "capital": "Ottawa"
    },
    {
        "timezones": [
            "Indian/Cocos"
        ], 
        "latlng": [
            -12.1708739,
            96.8417393
        ], 
        "name": "Cocos (Keeling) Islands", 
        "capital": "West Island"
    }, 
    {
        "timezones": [
            "Europe/Zurich"
        ], 
        "latlng": [
            47.373878, 
            8.545094
        ], 
        "name": "Switzerland", 
        "capital": "Bern"
    }, 
    {
        "timezones": [
            "America/Santiago"
        ], 
        "latlng": [
            -33.459229, 
            -70.645348
        ], 
        "name": "Chile", 
        "capital": "Santiago"
    }, 
    {
        "timezones": [
            "Pacific/Easter"
        ], 
        "latlng": [
            -27.104671, 
            -109.360481
        ], 
        "name": "Chile", 
        "capital": "Santiago"
    }, 
    {
        "timezones": [
            "Asia/Shanghai"
        ], 
        "latlng": [
            31.224361, 
            121.469170
        ], 
        "name": "China", 
        "capital": "Beijing"
    }, 
    {
        "timezones": [
            "Asia/Urumqi"
        ], 
        "latlng": [
            43.825592, 
            87.616848
        ], 
        "name": "China", 
        "capital": "Beijing"
    }, 
    {
        "timezones": [
            "Africa/Abidjan"
        ], 
        "latlng": [
            5.345317, 
            -4.024429
        ], 
        "name": "Ivory Coast", 
        "capital": "Yamoussoukro"
    }, 
    {
        "timezones": [
            "Africa/Douala"
        ], 
        "latlng": [
            4.058907, 
            9.702895
        ], 
        "name": "Cameroon", 
        "capital": "Yaound\u00e9"
    }, 
    {
        "timezones": [
            "Africa/Kinshasa"
        ], 
        "latlng": [
            -4.32758, 
            15.31357
        ], 
        "name": "DR Congo", 
        "capital": "Kinshasa"
    }, 
    {
        "timezones": [
            "Africa/Lubumbashi"
        ], 
        "latlng": [
            -11.66089, 
            27.47938
        ], 
        "name": "DR Congo", 
        "capital": "Kinshasa"
    }, 
    {
        "timezones": [
            "Africa/Brazzaville"
        ], 
        "latlng": [
            -4.263360, 
            15.242885
        ], 
        "name": "Republic of the Congo", 
        "capital": "Brazzaville"
    }, 
    {
        "timezones": [
            "Pacific/Rarotonga"
        ], 
        "latlng": [
            -21.23333333, 
            -159.76666666
        ], 
        "name": "Cook Islands", 
        "capital": "Avarua"
    }, 
    {
        "timezones": [
            "America/Bogota"
        ], 
        "latlng": [
            4.624335, 
            -74.063644
        ], 
        "name": "Colombia", 
        "capital": "Bogot\u00e1"
    }, 
    {
        "timezones": [
            "Indian/Comoro"
        ], 
        "latlng": [
            -11.700730, 
            43.270282
        ], 
        "name": "Comoros", 
        "capital": "Moroni"
    }, 
    {
        "timezones": [
            "Atlantic/Cape_Verde"
        ], 
        "latlng": [
            15.120142,
            -23.6051721
        ], 
        "name": "Cape Verde", 
        "capital": "Praia"
    }, 
    {
        "timezones": [
            "America/Costa_Rica"
        ], 
        "latlng": [
            9.934739, 
            -84.087502
        ], 
        "name": "Costa Rica", 
        "capital": "San Jos\u00e9"
    }, 
    {
        "timezones": [
            "America/Havana"
        ], 
        "latlng": [
            37.091736, 
            -95.941925
        ], 
        "name": "Cuba", 
        "capital": "Havana"
    }, 
    {
        "timezones": [
            "America/Curacao"
        ], 
        "latlng": [
            12.116667, 
            -68.933333
        ], 
        "name": "Cura\u00e7ao", 
        "capital": "Willemstad"
    }, 
    {
        "timezones": [
            "Indian/Christmas"
        ], 
        "latlng": [
            -10.5, 
            105.66666666
        ], 
        "name": "Christmas Island", 
        "capital": "Flying Fish Cove"
    }, 
    {
        "timezones": [
            "America/Cayman"
        ], 
        "latlng": [
            19.292997, 
            -81.366806
        ], 
        "name": "Cayman Islands", 
        "capital": "George Town"
    }, 
    {
        "timezones": [
            "Asia/Nicosia"
        ], 
        "latlng": [
            35.16666, 
            33.36666
        ], 
        "name": "Cyprus", 
        "capital": "Nicosia"
    }, 
    {
        "timezones": [
            "Europe/Prague"
        ], 
        "latlng": [
            50.073658, 
            14.418540
        ], 
        "name": "Czech Republic", 
        "capital": "Prague"
    }, 
    {
        "timezones": [
            "Europe/Berlin"
        ], 
        "latlng": [
            52.520008, 
            13.404954
        ], 
        "name": "Germany", 
        "capital": "Berlin"
    }, 
    {
        "timezones": [
            "Europe/Busingen"
        ], 
        "latlng": [
            47.696001, 
            8.688954
        ], 
        "name": "Germany", 
        "capital": "Berlin"
    }, 
    {
        "timezones": [
            "Africa/Djibouti"
        ], 
        "latlng": [
            11.572076, 
            43.145645
        ], 
        "name": "Djibouti", 
        "capital": "Djibouti"
    }, 
    {
        "timezones": [
            "America/Dominica"
        ], 
        "latlng": [
            15.240796, 
            -61.314869
        ], 
        "name": "Dominica", 
        "capital": "Roseau"
    }, 
    {
        "timezones": [
            "Europe/Copenhagen"
        ], 
        "latlng": [
            55.676097, 
            12.568337
        ], 
        "name": "Denmark", 
        "capital": "Copenhagen"
    }, 
    {
        "timezones": [
            "America/Santo_Domingo"
        ], 
        "latlng": [
            18.483402, 
            -69.929611
        ], 
        "name": "Dominican Republic", 
        "capital": "Santo Domingo"
    }, 
    {
        "timezones": [
            "Africa/Algiers"
        ], 
        "latlng": [
            28.0289837,
            1.6666663
        ], 
        "name": "Algeria", 
        "capital": "Algiers"
    }, 
    {
        "timezones": [
            "America/Guayaquil" 
        ], 
        "latlng": [
            -2.203816, 
            -79.897453
        ], 
        "name": "Ecuador", 
        "capital": "Quito"
    }, 
    {
        "timezones": [
            "Pacific/Galapagos"
        ], 
        "latlng": [
            -0.777259, 
            -91.142578
        ], 
        "name": "Ecuador", 
        "capital": "Quito"
    },
    {
        "timezones": [
            "Africa/Cairo"
        ], 
        "latlng": [
            30.033333, 
            31.233334
        ], 
        "name": "Egypt", 
        "capital": "Cairo"
    }, 
    {
        "timezones": [
            "Africa/Asmara"
        ], 
        "latlng": [
            15.322877, 
            38.925052
        ], 
        "name": "Eritrea", 
        "capital": "Asmara"
    }, 
    {
        "timezones": [
            "Africa/El_Aaiun"
        ], 
        "latlng": [
            27.1418, 
            -13.18797
        ], 
        "name": "Western Sahara", 
        "capital": "El Aai\u00fan"
    }, 
    {
        "timezones": [
            "Atlantic/Canary"
        ], 
        "latlng": [
            28.291565, 
            -16.629129
        ], 
        "name": "Spain", 
        "capital": "Madrid"
    }, 
    {
        "timezones": [
            "Europe/Madrid"
        ], 
        "latlng": [
            40.416775, 
            -3.703790
        ], 
        "name": "Spain", 
        "capital": "Madrid"
    }, 
    {
        "timezones": [
            "Africa/Ceuta"
        ], 
        "latlng": [
            35.88933,
            -5.31979
        ], 
        "name": "Spain", 
        "capital": "Madrid"
    }, 
    {
        "timezones": [
            "Europe/Tallinn"
        ], 
        "latlng": [
            59.4338889, 
            24.7280556
        ], 
        "name": "Estonia", 
        "capital": "Tallinn"
    }, 
    {
        "timezones": [
            "Africa/Addis_Ababa"
        ], 
        "latlng": [
            9.005401, 
            38.763611
        ], 
        "name": "Ethiopia", 
        "capital": "Addis Ababa"
    }, 
    {
        "timezones": [
            "Europe/Helsinki"
        ], 
        "latlng": [
            60.169856, 
            24.938379
        ], 
        "name": "Finland", 
        "capital": "Helsinki"
    }, 
    {
        "timezones": [
            "Pacific/Fiji"
        ], 
        "latlng": [
            -17.713371, 
            178.065033
        ], 
        "name": "Fiji", 
        "capital": "Suva"
    }, 
    {
        "timezones": [
            "Atlantic/Stanley"
        ], 
        "latlng": [
            -51.697713, 
            -57.851663
        ], 
        "name": "Falkland Islands", 
        "capital": "Stanley"
    }, 
    {
        "timezones": [
            "Europe/Paris"
        ], 
        "latlng": [
            48.864716, 
            2.349014
        ], 
        "name": "France", 
        "capital": "Paris"
    }, 
    {
        "timezones": [
            "Atlantic/Faroe"
        ], 
        "latlng": [
            61.8925022, 
            -6.9729703
        ], 
        "name": "Faroe Islands", 
        "capital": "T\u00f3rshavn"
    }, 
    {
        "timezones": [
            "Pacific/Kosrae"
        ], 
        "latlng": [
            5.31666, 
            162.98333
        ], 
        "name": "Micronesia", 
        "capital": "Palikir"
    }, 
    {
        "timezones": [
            "Pacific/Chuuk"
        ], 
        "latlng": [
            7.416665,
            151.7833302
        ], 
        "name": "Micronesia", 
        "capital": "Palikir"
    }, 
    {
        "timezones": [
            "Pacific/Pohnpei" 
        ], 
        "latlng": [
            6.891854461, 
            158.2151372273
        ], 
        "name": "Micronesia", 
        "capital": "Palikir"
    }, 
    {
        "timezones": [
            "Africa/Libreville"
        ], 
        "latlng": [
            0.39241, 
            9.45356
        ], 
        "name": "Gabon", 
        "capital": "Libreville"
    }, 
    {
        "timezones": [
            "Europe/London"
        ], 
        "latlng": [
            51.50853,
            -0.12574
        ], 
        "name": "United Kingdom", 
        "capital": "London"
    }, 
    {
        "timezones": [
            "Asia/Tbilisi"
        ], 
        "latlng": [
            41.716667, 
            44.783333
        ], 
        "name": "Georgia", 
        "capital": "Tbilisi"
    }, 
    {
        "timezones": [
            "Europe/Guernsey"
        ], 
        "latlng": [
            49.46666666, 
            -2.58333333
        ], 
        "name": "Guernsey", 
        "capital": "St. Peter Port"
    }, 
    {
        "timezones": [
            "Africa/Accra"
        ], 
        "latlng": [
            5.614818, 
            -0.205874
        ], 
        "name": "Ghana", 
        "capital": "Accra"
    }, 
    {
        "timezones": [
            "Europe/Gibraltar"
        ], 
        "latlng": [
            35.946339, 
            -5.655601
        ], 
        "name": "Gibraltar", 
        "capital": "Gibraltar"
    }, 
    {
        "timezones": [
            "Africa/Conakry"
        ], 
        "latlng": [
            9.509167, 
            -13.712222
        ], 
        "name": "Guinea", 
        "capital": "Conakry"
    }, 
    {
        "timezones": [
            "America/Guadeloupe"
        ], 
        "latlng": [
            16.270000, 
            -61.580002
        ], 
        "name": "Guadeloupe", 
        "capital": "Basse-Terre"
    }, 
    {
        "timezones": [
            "Africa/Banjul"
        ], 
        "latlng": [
            13.45274, 
            -16.57803
        ], 
        "name": "Gambia", 
        "capital": "Banjul"
    }, 
    {
        "timezones": [
            "Africa/Bissau"
        ], 
        "latlng": [
            11.86357, 
            -15.59767
        ], 
        "name": "Guinea-Bissau", 
        "capital": "Bissau"
    }, 
    {
        "timezones": [
            "Africa/Malabo"
        ], 
        "latlng": [
            3.75, 
            8.78333
        ], 
        "name": "Equatorial Guinea", 
        "capital": "Malabo"
    }, 
    {
        "timezones": [
            "Europe/Athens"
        ], 
        "latlng": [
            37.983810, 
            23.727539
        ], 
        "name": "Greece", 
        "capital": "Athens"
    }, 
    {
        "timezones": [
            "America/Grenada"
        ], 
        "latlng": [
            12.104818, 
            -61.670761
        ], 
        "name": "Grenada", 
        "capital": "St. George's"
    }, 
    {
        "timezones": [
            "America/Godthab"
        ], 
        "latlng": [
            65.407676, 
            -52.900572
        ], 
        "name": "Greenland", 
        "capital": "Nuuk"
    }, 
    {
        "timezones": [
            "America/Danmarkshavn"
        ], 
        "latlng": [
            76.76666, 
            -18.66666
        ], 
        "name": "Greenland", 
        "capital": "Nuuk"
    }, 
    {
        "timezones": [
            "America/Scoresbysund"
        ], 
        "latlng": [
            70.48333, 
            -21.96666
        ], 
        "name": "Greenland", 
        "capital": "Nuuk"
    }, 
    {
        "timezones": [
            "America/Thule"
        ], 
        "latlng": [
            76.529999, 
            -68.705833
        ], 
        "name": "Greenland", 
        "capital": "Nuuk"
    }, 
    {
        "timezones": [
            "America/Guatemala"
        ], 
        "latlng": [
            14.628434, 
            -90.522713
        ], 
        "name": "Guatemala", 
        "capital": "Guatemala City"
    }, 
    {
        "timezones": [
            "America/Cayenne"
        ], 
        "latlng": [
            4.93333, 
            -52.33333
        ], 
        "name": "French Guiana", 
        "capital": "Cayenne"
    }, 
    {
        "timezones": [
            "Pacific/Guam"
        ], 
        "latlng": [
            13.444304, 
            144.793732
        ], 
        "name": "Guam", 
        "capital": "Hag\u00e5t\u00f1a"
    }, 
    {
        "timezones": [
            "America/Guyana"
        ], 
        "latlng": [
            4.8556759,
            -58.9530125
        ], 
        "name": "Guyana", 
        "capital": "Georgetown"
    }, 
    {
        "timezones": [
            "Asia/Hong_Kong"
        ], 
        "latlng": [
            22.396428, 
            114.109497
        ], 
        "name": "Hong Kong", 
        "capital": "City of Victoria"
    },
    {
        "timezones": [
            "America/Tegucigalpa"
        ], 
        "latlng": [
            14.081999, 
            -87.202438
        ], 
        "name": "Honduras", 
        "capital": "Tegucigalpa"
    }, 
    {
        "timezones": [
            "Europe/Zagreb"
        ], 
        "latlng": [
            45.81444, 
            15.97798
        ], 
        "name": "Croatia", 
        "capital": "Zagreb"
    }, 
    {
        "timezones": [
            "America/Port-au-Prince"
        ], 
        "latlng": [
            18.533333, 
            -72.333336
        ], 
        "name": "Haiti", 
        "capital": "Port-au-Prince"
    }, 
    {
        "timezones": [
            "Europe/Budapest"
        ], 
        "latlng": [
            47.497913, 
            19.040236
        ], 
        "name": "Hungary", 
        "capital": "Budapest"
    }, 
    {
        "timezones": [
            "Asia/Jakarta"
        ], 
        "latlng": [
            -6.200000, 
            106.816666
        ], 
        "name": "Indonesia", 
        "capital": "Jakarta"
    }, 
    {
        "timezones": [
            "Asia/Pontianak"
        ], 
        "latlng": [
            -0.03109, 
            109.32199
        ], 
        "name": "Indonesia", 
        "capital": "Jakarta"
    }, 
    {
        "timezones": [
            "Asia/Makassar"
        ], 
        "latlng": [
            -5.14, 
            119.4221
        ], 
        "name": "Indonesia", 
        "capital": "Jakarta"
    }, 
    {
        "timezones": [
            "Asia/Jayapura"
        ], 
        "latlng": [
            -2.591603, 
            140.669000
        ], 
        "name": "Indonesia", 
        "capital": "Jakarta"
    }, 
    {
        "timezones": [
            "Europe/Isle_of_Man"
        ], 
        "latlng": [
            54.15, 
            -4.46666
        ], 
        "name": "Isle of Man", 
        "capital": "Douglas"
    }, 
    {
        "timezones": [
            "Asia/Kolkata"
        ], 
        "latlng": [
            22.572645, 
            88.363892
        ], 
        "name": "India", 
        "capital": "New Delhi"
    }, 
    {
        "timezones": [
            "Indian/Chagos"
        ], 
        "latlng": [
            -4.416665,
            72.5999976
        ], 
        "name": "British Indian Ocean Territory", 
        "capital": "Diego Garcia"
    }, 
    {
        "timezones": [
            "Europe/Dublin"
        ], 
        "latlng": [
            53.33306,
            -6.24889
        ], 
        "name": "Ireland", 
        "capital": "Dublin"
    }, 
    {
        "timezones": [
            "Asia/Tehran"
        ], 
        "latlng": [
            35.689197, 
            51.388974
        ], 
        "name": "Iran", 
        "capital": "Tehran"
    }, 
    {
        "timezones": [
            "Asia/Baghdad"
        ], 
        "latlng": [
            33.312805, 
            44.361488
        ], 
        "name": "Iraq", 
        "capital": "Baghdad"
    }, 
    {
        "timezones": [
            "Atlantic/Reykjavik"
        ], 
        "latlng": [
            64.126521, 
            -21.817439
        ], 
        "name": "Iceland", 
        "capital": "Reykjavik"
    }, 
    {
        "timezones": [
            "Asia/Jerusalem"
        ], 
        "latlng": [
            31.771959, 
            35.217018
        ], 
        "name": "Israel", 
        "capital": "Jerusalem"
    }, 
    {
        "timezones": [
            "Asia/Tel_Aviv"
        ], 
        "latlng": [
            32.109333, 
            34.855499
        ], 
        "name": "Israel", 
        "capital": "Jerusalem"
    }, 
    {
        "timezones": [
            "Africa/Asmara"
        ], 
        "latlng": [
            15.33805, 
            38.93184
        ], 
        "name": "Eritrea", 
        "capital": "Asmara"
    }, 
    {
        "timezones": [
            "Europe/Rome"
        ], 
        "latlng": [
            41.902782, 
            12.496366
        ], 
        "name": "Italy", 
        "capital": "Rome"
    }, 
    {
        "timezones": [
            "America/Jamaica"
        ], 
        "latlng": [
            40.699783, 
            -73.786026
        ], 
        "name": "Jamaica", 
        "capital": "Kingston"
    }, 
    {
        "timezones": [
            "Europe/Jersey"
        ], 
        "latlng": [
            49.214439, 
            -2.131250
        ], 
        "name": "Jersey", 
        "capital": "Saint Helier"
    }, 
    {
        "timezones": [
            "Asia/Amman"
        ], 
        "latlng": [
            31.963158, 
            35.930359
        ], 
        "name": "Jordan", 
        "capital": "Amman"
    }, 
    {
        "timezones": [
            "Asia/Tokyo"
        ], 
        "latlng": [
            35.652832, 
            139.839478
        ], 
        "name": "Japan", 
        "capital": "Tokyo"
    }, 
    {
        "timezones": [
            "Asia/Almaty", 
            "Asia/Qyzylorda", 
            "Asia/Aqtobe", 
            "Asia/Aqtau", 
            "Asia/Oral"
        ], 
        "latlng": [
            48, 
            68
        ], 
        "name": "Kazakhstan", 
        "capital": "Astana"
    }, 
    {
        "timezones": [
            "Africa/Nairobi"
        ], 
        "latlng": [
            1, 
            38
        ], 
        "name": "Kenya", 
        "capital": "Nairobi"
    }, 
    {
        "timezones": [
            "Asia/Bishkek"
        ], 
        "latlng": [
            41, 
            75
        ], 
        "name": "Kyrgyzstan", 
        "capital": "Bishkek"
    }, 
    {
        "timezones": [
            "Asia/Phnom_Penh"
        ], 
        "latlng": [
            13, 
            105
        ], 
        "name": "Cambodia", 
        "capital": "Phnom Penh"
    }, 
    {
        "timezones": [
            "Pacific/Tarawa", 
            "Pacific/Enderbury", 
            "Pacific/Kiritimati"
        ], 
        "latlng": [
            1.41666666, 
            173
        ], 
        "name": "Kiribati", 
        "capital": "South Tarawa"
    }, 
    {
        "timezones": [
            "America/St_Kitts"
        ], 
        "latlng": [
            17.33333333, 
            -62.75
        ], 
        "name": "Saint Kitts and Nevis", 
        "capital": "Basseterre"
    }, 
    {
        "timezones": [
            "Asia/Seoul"
        ], 
        "latlng": [
            37, 
            127.5
        ], 
        "name": "South Korea", 
        "capital": "Seoul"
    }, 
    {
        "timezones": ["Europe/Belgrade"], 
        "latlng": [
            42.666667, 
            21.166667
        ], 
        "name": "Kosovo", 
        "capital": "Pristina"
    }, 
    {
        "timezones": [
            "Asia/Kuwait"
        ], 
        "latlng": [
            29.5, 
            45.75
        ], 
        "name": "Kuwait", 
        "capital": "Kuwait City"
    }, 
    {
        "timezones": [
            "Asia/Vientiane"
        ], 
        "latlng": [
            18, 
            105
        ], 
        "name": "Laos", 
        "capital": "Vientiane"
    }, 
    {
        "timezones": [
            "Asia/Beirut"
        ], 
        "latlng": [
            33.83333333, 
            35.83333333
        ], 
        "name": "Lebanon", 
        "capital": "Beirut"
    }, 
    {
        "timezones": [
            "Africa/Monrovia"
        ], 
        "latlng": [
            6.5, 
            -9.5
        ], 
        "name": "Liberia", 
        "capital": "Monrovia"
    }, 
    {
        "timezones": [
            "Africa/Tripoli"
        ], 
        "latlng": [
            25, 
            17
        ], 
        "name": "Libya", 
        "capital": "Tripoli"
    }, 
    {
        "timezones": [
            "America/St_Lucia"
        ], 
        "latlng": [
            13.88333333, 
            -60.96666666
        ], 
        "name": "Saint Lucia", 
        "capital": "Castries"
    }, 
    {
        "timezones": [
            "Europe/Vaduz"
        ], 
        "latlng": [
            47.26666666, 
            9.53333333
        ], 
        "name": "Liechtenstein", 
        "capital": "Vaduz"
    }, 
    {
        "timezones": [
            "Asia/Colombo"
        ], 
        "latlng": [
            7, 
            81
        ], 
        "name": "Sri Lanka", 
        "capital": "Colombo"
    }, 
    {
        "timezones": [
            "Africa/Maseru"
        ], 
        "latlng": [
            -29.5, 
            28.5
        ], 
        "name": "Lesotho", 
        "capital": "Maseru"
    }, 
    {
        "timezones": [
            "Europe/Vilnius"
        ], 
        "latlng": [
            56, 
            24
        ], 
        "name": "Lithuania", 
        "capital": "Vilnius"
    }, 
    {
        "timezones": [
            "Europe/Luxembourg"
        ], 
        "latlng": [
            49.75, 
            6.16666666
        ], 
        "name": "Luxembourg", 
        "capital": "Luxembourg"
    }, 
    {
        "timezones": [
            "Europe/Riga"
        ], 
        "latlng": [
            57, 
            25
        ], 
        "name": "Latvia", 
        "capital": "Riga"
    }, 
    {
        "timezones": [
            "Asia/Macau"
        ], 
        "latlng": [
            22.16666666, 
            113.55
        ], 
        "name": "Macau", 
        "capital": null
    }, 
    {
        "timezones": [
            "America/Marigot"
        ], 
        "latlng": [
            18.08333333, 
            -63.95
        ], 
        "name": "Saint Martin", 
        "capital": "Marigot"
    }, 
    {
        "timezones": [
            "Africa/Casablanca"
        ], 
        "latlng": [
            32, 
            -5
        ], 
        "name": "Morocco", 
        "capital": "Rabat"
    }, 
    {
        "timezones": [
            "Europe/Monaco"
        ], 
        "latlng": [
            43.73333333, 
            7.4
        ], 
        "name": "Monaco", 
        "capital": "Monaco"
    }, 
    {
        "timezones": [
            "Europe/Chisinau"
        ], 
        "latlng": [
            47, 
            29
        ], 
        "name": "Moldova", 
        "capital": "Chi\u0219in\u0103u"
    }, 
    {
        "timezones": [
            "Indian/Antananarivo"
        ], 
        "latlng": [
            -20, 
            47
        ], 
        "name": "Madagascar", 
        "capital": "Antananarivo"
    }, 
    {
        "timezones": [
            "Indian/Maldives"
        ], 
        "latlng": [
            3.25, 
            73
        ], 
        "name": "Maldives", 
        "capital": "Mal\u00e9"
    }, 
    {
        "timezones": [
            "America/Mexico_City", 
            "America/Cancun", 
            "America/Merida", 
            "America/Monterrey", 
            "America/Matamoros", 
            "America/Mazatlan", 
            "America/Chihuahua", 
            "America/Ojinaga", 
            "America/Hermosillo", 
            "America/Tijuana", 
            "America/Bahia_Banderas"
        ], 
        "latlng": [
            23, 
            -102
        ], 
        "name": "Mexico", 
        "capital": "Mexico City"
    }, 
    {
        "timezones": [
            "Pacific/Majuro", 
            "Pacific/Kwajalein"
        ], 
        "latlng": [
            9, 
            168
        ], 
        "name": "Marshall Islands", 
        "capital": "Majuro"
    }, 
    {
        "timezones": [
            "Europe/Skopje"
        ], 
        "latlng": [
            41.83333333, 
            22
        ], 
        "name": "Macedonia", 
        "capital": "Skopje"
    }, 
    {
        "timezones": [
            "Africa/Bamako"
        ], 
        "latlng": [
            17, 
            -4
        ], 
        "name": "Mali", 
        "capital": "Bamako"
    }, 
    {
        "timezones": [
            "Europe/Malta"
        ], 
        "latlng": [
            35.83333333, 
            14.58333333
        ], 
        "name": "Malta", 
        "capital": "Valletta"
    }, 
    {
        "timezones": [
            "Asia/Rangoon"
        ], 
        "latlng": [
            22, 
            98
        ], 
        "name": "Myanmar", 
        "capital": "Naypyidaw"
    }, 
    {
        "timezones": [
            "Europe/Podgorica"
        ], 
        "latlng": [
            42.5, 
            19.3
        ], 
        "name": "Montenegro", 
        "capital": "Podgorica"
    }, 
    {
        "timezones": [
            "Asia/Ulaanbaatar", 
            "Asia/Hovd", 
            "Asia/Choibalsan"
        ], 
        "latlng": [
            46, 
            105
        ], 
        "name": "Mongolia", 
        "capital": "Ulan Bator"
    }, 
    {
        "timezones": [
            "Pacific/Saipan"
        ], 
        "latlng": [
            15.2, 
            145.75
        ], 
        "name": "Northern Mariana Islands", 
        "capital": "Saipan"
    }, 
    {
        "timezones": [
            "Africa/Maputo"
        ], 
        "latlng": [
            -18.25, 
            35
        ], 
        "name": "Mozambique", 
        "capital": "Maputo"
    }, 
    {
        "timezones": [
            "Africa/Nouakchott"
        ], 
        "latlng": [
            20, 
            -12
        ], 
        "name": "Mauritania", 
        "capital": "Nouakchott"
    }, 
    {
        "timezones": [
            "America/Montserrat"
        ], 
        "latlng": [
            16.75, 
            -62.2
        ], 
        "name": "Montserrat", 
        "capital": "Plymouth"
    }, 
    {
        "timezones": [
            "America/Martinique"
        ], 
        "latlng": [
            14.666667, 
            -61
        ], 
        "name": "Martinique", 
        "capital": "Fort-de-France"
    }, 
    {
        "timezones": [
            "Indian/Mauritius"
        ], 
        "latlng": [
            -20.28333333, 
            57.55
        ], 
        "name": "Mauritius", 
        "capital": "Port Louis"
    }, 
    {
        "timezones": [
            "Africa/Blantyre"
        ], 
        "latlng": [
            -13.5, 
            34
        ], 
        "name": "Malawi", 
        "capital": "Lilongwe"
    }, 
    {
        "timezones": [
            "Asia/Kuala_Lumpur", 
            "Asia/Kuching"
        ], 
        "latlng": [
            2.5, 
            112.5
        ], 
        "name": "Malaysia", 
        "capital": "Kuala Lumpur"
    }, 
    {
        "timezones": [
            "Indian/Mayotte"
        ], 
        "latlng": [
            -12.83333333, 
            45.16666666
        ], 
        "name": "Mayotte", 
        "capital": "Mamoudzou"
    }, 
    {
        "timezones": [
            "Africa/Windhoek"
        ], 
        "latlng": [
            -22, 
            17
        ], 
        "name": "Namibia", 
        "capital": "Windhoek"
    }, 
    {
        "timezones": [
            "Pacific/Noumea"
        ], 
        "latlng": [
            -21.5, 
            165.5
        ], 
        "name": "New Caledonia", 
        "capital": "Noum\u00e9a"
    }, 
    {
        "timezones": [
            "Africa/Niamey"
        ], 
        "latlng": [
            16, 
            8
        ], 
        "name": "Niger", 
        "capital": "Niamey"
    }, 
    {
        "timezones": [
            "Pacific/Norfolk"
        ], 
        "latlng": [
            -29.03333333, 
            167.95
        ], 
        "name": "Norfolk Island", 
        "capital": "Kingston"
    }, 
    {
        "timezones": [
            "Africa/Lagos"
        ], 
        "latlng": [
            10, 
            8
        ], 
        "name": "Nigeria", 
        "capital": "Abuja"
    }, 
    {
        "timezones": [
            "America/Managua"
        ], 
        "latlng": [
            13, 
            -85
        ], 
        "name": "Nicaragua", 
        "capital": "Managua"
    }, 
    {
        "timezones": [
            "Pacific/Niue"
        ], 
        "latlng": [
            -19.03333333, 
            -169.86666666
        ], 
        "name": "Niue", 
        "capital": "Alofi"
    }, 
    {
        "timezones": [
            "Europe/Amsterdam"
        ], 
        "latlng": [
            52.5, 
            5.75
        ], 
        "name": "Netherlands", 
        "capital": "Amsterdam"
    }, 
    {
        "timezones": [
            "Europe/Oslo"
        ], 
        "latlng": [
            62, 
            10
        ], 
        "name": "Norway", 
        "capital": "Oslo"
    }, 
    {
        "timezones": [
            "Asia/Kathmandu"
        ], 
        "latlng": [
            28, 
            84
        ], 
        "name": "Nepal", 
        "capital": "Kathmandu"
    }, 
    {
        "timezones": [
            "Pacific/Nauru"
        ], 
        "latlng": [
            -0.53333333, 
            166.91666666
        ], 
        "name": "Nauru", 
        "capital": "Yaren"
    }, 
    {
        "timezones": [
            "Pacific/Auckland", 
            "Pacific/Chatham"
        ], 
        "latlng": [
            -41, 
            174
        ], 
        "name": "New Zealand", 
        "capital": "Wellington"
    }, 
    {
        "timezones": [
            "Asia/Muscat"
        ], 
        "latlng": [
            21, 
            57
        ], 
        "name": "Oman", 
        "capital": "Muscat"
    }, 
    {
        "timezones": [
            "Asia/Karachi"
        ], 
        "latlng": [
            30, 
            70
        ], 
        "name": "Pakistan", 
        "capital": "Islamabad"
    }, 
    {
        "timezones": [
            "America/Panama"
        ], 
        "latlng": [
            9, 
            -80
        ], 
        "name": "Panama", 
        "capital": "Panama City"
    }, 
    {
        "timezones": [
            "Pacific/Pitcairn"
        ], 
        "latlng": [
            -25.06666666, 
            -130.1
        ], 
        "name": "Pitcairn Islands", 
        "capital": "Adamstown"
    }, 
    {
        "timezones": [
            "America/Lima"
        ], 
        "latlng": [
            -10, 
            -76
        ], 
        "name": "Peru", 
        "capital": "Lima"
    }, 
    {
        "timezones": [
            "Asia/Manila"
        ], 
        "latlng": [
            13, 
            122
        ], 
        "name": "Philippines", 
        "capital": "Manila"
    }, 
    {
        "timezones": [
            "Pacific/Palau"
        ], 
        "latlng": [
            7.5, 
            134.5
        ], 
        "name": "Palau", 
        "capital": "Ngerulmud"
    }, 
    {
        "timezones": [
            "Pacific/Port_Moresby", 
            "Pacific/Bougainville"
        ], 
        "latlng": [
            -6, 
            147
        ], 
        "name": "Papua New Guinea", 
        "capital": "Port Moresby"
    }, 
    {
        "timezones": [
            "Europe/Warsaw"
        ], 
        "latlng": [
            52, 
            20
        ], 
        "name": "Poland", 
        "capital": "Warsaw"
    }, 
    {
        "timezones": [
            "America/Puerto_Rico"
        ], 
        "latlng": [
            18.25, 
            -66.5
        ], 
        "name": "Puerto Rico", 
        "capital": "San Juan"
    }, 
    {
        "timezones": [
            "Asia/Pyongyang"
        ], 
        "latlng": [
            40, 
            127
        ], 
        "name": "North Korea", 
        "capital": "Pyongyang"
    }, 
    {
        "timezones": [
            "Europe/Lisbon", 
            "Atlantic/Madeira", 
            "Atlantic/Azores"
        ], 
        "latlng": [
            39.5, 
            -8
        ], 
        "name": "Portugal", 
        "capital": "Lisbon"
    }, 
    {
        "timezones": [
            "America/Asuncion"
        ], 
        "latlng": [
            -23, 
            -58
        ], 
        "name": "Paraguay", 
        "capital": "Asunci\u00f3n"
    }, 
    {
        "timezones": [
            "Asia/Gaza", 
            "Asia/Hebron"
        ], 
        "latlng": [
            31.9, 
            35.2
        ], 
        "name": "Palestine", 
        "capital": "Ramallah"
    }, 
    {
        "timezones": [
            "Pacific/Tahiti", 
            "Pacific/Marquesas", 
            "Pacific/Gambier"
        ], 
        "latlng": [
            -15, 
            -140
        ], 
        "name": "French Polynesia", 
        "capital": "Papeet\u0113"
    }, 
    {
        "timezones": [
            "Asia/Qatar"
        ], 
        "latlng": [
            25.5, 
            51.25
        ], 
        "name": "Qatar", 
        "capital": "Doha"
    }, 
    {
        "timezones": [
            "Indian/Reunion"
        ], 
        "latlng": [
            -21.15, 
            55.5
        ], 
        "name": "R\u00e9union", 
        "capital": "Saint-Denis"
    }, 
    {
        "timezones": [
            "Europe/Bucharest"
        ], 
        "latlng": [
            46, 
            25
        ], 
        "name": "Romania", 
        "capital": "Bucharest"
    }, 
    {
        "timezones": [
            "Europe/Kaliningrad", 
            "Europe/Moscow", 
            "Europe/Simferopol", 
            "Europe/Volgograd", 
            "Europe/Kirov", 
            "Europe/Astrakhan", 
            "Europe/Samara", 
            "Europe/Ulyanovsk", 
            "Asia/Yekaterinburg", 
            "Asia/Omsk", 
            "Asia/Novosibirsk", 
            "Asia/Barnaul", 
            "Asia/Tomsk", 
            "Asia/Novokuznetsk", 
            "Asia/Krasnoyarsk", 
            "Asia/Irkutsk", 
            "Asia/Chita", 
            "Asia/Yakutsk", 
            "Asia/Khandyga", 
            "Asia/Vladivostok", 
            "Asia/Ust-Nera", 
            "Asia/Magadan", 
            "Asia/Sakhalin", 
            "Asia/Srednekolymsk", 
            "Asia/Kamchatka", 
            "Asia/Anadyr"
        ], 
        "latlng": [
            60, 
            100
        ], 
        "name": "Russia", 
        "capital": "Moscow"
    }, 
    {
        "timezones": [
            "Africa/Kigali"
        ], 
        "latlng": [
            -2, 
            30
        ], 
        "name": "Rwanda", 
        "capital": "Kigali"
    }, 
    {
        "timezones": [
            "Asia/Riyadh"
        ], 
        "latlng": [
            25, 
            45
        ], 
        "name": "Saudi Arabia", 
        "capital": "Riyadh"
    }, 
    {
        "timezones": [
            "Africa/Khartoum"
        ], 
        "latlng": [
            15, 
            30
        ], 
        "name": "Sudan", 
        "capital": "Khartoum"
    }, 
    {
        "timezones": [
            "Africa/Dakar"
        ], 
        "latlng": [
            14, 
            -14
        ], 
        "name": "Senegal", 
        "capital": "Dakar"
    }, 
    {
        "timezones": [
            "Asia/Singapore"
        ], 
        "latlng": [
            1.36666666, 
            103.8
        ], 
        "name": "Singapore", 
        "capital": "Singapore"
    }, 
    {
        "timezones": [
            "Atlantic/South_Georgia"
        ], 
        "latlng": [
            -54.5, 
            -37
        ], 
        "name": "South Georgia", 
        "capital": "King Edward Point"
    }, 
    {
        "timezones": [
            "Arctic/Longyearbyen"
        ], 
        "latlng": [
            78, 
            20
        ], 
        "name": "Svalbard and Jan Mayen", 
        "capital": "Longyearbyen"
    }, 
    {
        "timezones": [
            "Pacific/Guadalcanal"
        ], 
        "latlng": [
            -8, 
            159
        ], 
        "name": "Solomon Islands", 
        "capital": "Honiara"
    }, 
    {
        "timezones": [
            "Africa/Freetown"
        ], 
        "latlng": [
            8.5, 
            -11.5
        ], 
        "name": "Sierra Leone", 
        "capital": "Freetown"
    }, 
    {
        "timezones": [
            "America/El_Salvador"
        ], 
        "latlng": [
            13.83333333, 
            -88.91666666
        ], 
        "name": "El Salvador", 
        "capital": "San Salvador"
    }, 
    {
        "timezones": [
            "Europe/San_Marino"
        ], 
        "latlng": [
            43.76666666, 
            12.41666666
        ], 
        "name": "San Marino", 
        "capital": "City of San Marino"
    }, 
    {
        "timezones": [
            "Africa/Mogadishu"
        ], 
        "latlng": [
            10, 
            49
        ], 
        "name": "Somalia", 
        "capital": "Mogadishu"
    }, 
    {
        "timezones": [
            "America/Miquelon"
        ], 
        "latlng": [
            46.83333333, 
            -56.33333333
        ], 
        "name": "Saint Pierre and Miquelon", 
        "capital": "Saint-Pierre"
    }, 
    {
        "timezones": [
            "Europe/Belgrade"
        ], 
        "latlng": [
            44, 
            21
        ], 
        "name": "Serbia", 
        "capital": "Belgrade"
    }, 
    {
        "timezones": [
            "Africa/Juba"
        ], 
        "latlng": [
            7, 
            30
        ], 
        "name": "South Sudan", 
        "capital": "Juba"
    }, 
    {
        "timezones": [
            "Africa/Sao_Tome"
        ], 
        "latlng": [
            1, 
            7
        ], 
        "name": "S\u00e3o Tom\u00e9 and Pr\u00edncipe", 
        "capital": "S\u00e3o Tom\u00e9"
    }, 
    {
        "timezones": [
            "America/Paramaribo"
        ], 
        "latlng": [
            4, 
            -56
        ], 
        "name": "Suriname", 
        "capital": "Paramaribo"
    }, 
    {
        "timezones": [
            "Europe/Bratislava"
        ], 
        "latlng": [
            48.66666666, 
            19.5
        ], 
        "name": "Slovakia", 
        "capital": "Bratislava"
    }, 
    {
        "timezones": [
            "Europe/Ljubljana"
        ], 
        "latlng": [
            46.11666666, 
            14.81666666
        ], 
        "name": "Slovenia", 
        "capital": "Ljubljana"
    }, 
    {
        "timezones": [
            "Europe/Stockholm"
        ], 
        "latlng": [
            62, 
            15
        ], 
        "name": "Sweden", 
        "capital": "Stockholm"
    }, 
    {
        "timezones": [
            "Africa/Mbabane"
        ], 
        "latlng": [
            -26.5, 
            31.5
        ], 
        "name": "Swaziland", 
        "capital": "Lobamba"
    }, 
    {
        "timezones": [
            "America/Lower_Princes"
        ], 
        "latlng": [
            18.033333, 
            -63.05
        ], 
        "name": "Sint Maarten", 
        "capital": "Philipsburg"
    }, 
    {
        "timezones": [
            "Indian/Mahe"
        ], 
        "latlng": [
            -4.58333333, 
            55.66666666
        ], 
        "name": "Seychelles", 
        "capital": "Victoria"
    }, 
    {
        "timezones": [
            "Asia/Damascus"
        ], 
        "latlng": [
            35, 
            38
        ], 
        "name": "Syria", 
        "capital": "Damascus"
    }, 
    {
        "timezones": [
            "America/Grand_Turk"
        ], 
        "latlng": [
            21.75, 
            -71.58333333
        ], 
        "name": "Turks and Caicos Islands", 
        "capital": "Cockburn Town"
    }, 
    {
        "timezones": [
            "Africa/Ndjamena"
        ], 
        "latlng": [
            15, 
            19
        ], 
        "name": "Chad", 
        "capital": "N'Djamena"
    }, 
    {
        "timezones": [
            "Africa/Lome"
        ], 
        "latlng": [
            8, 
            1.16666666
        ], 
        "name": "Togo", 
        "capital": "Lom\u00e9"
    }, 
    {
        "timezones": [
            "Asia/Bangkok"
        ], 
        "latlng": [
            15, 
            100
        ], 
        "name": "Thailand", 
        "capital": "Bangkok"
    }, 
    {
        "timezones": [
            "Asia/Dushanbe"
        ], 
        "latlng": [
            39, 
            71
        ], 
        "name": "Tajikistan", 
        "capital": "Dushanbe"
    }, 
    {
        "timezones": [
            "Pacific/Fakaofo"
        ], 
        "latlng": [
            -9, 
            -172
        ], 
        "name": "Tokelau", 
        "capital": "Fakaofo"
    }, 
    {
        "timezones": [
            "Asia/Ashgabat"
        ], 
        "latlng": [
            40, 
            60
        ], 
        "name": "Turkmenistan", 
        "capital": "Ashgabat"
    }, 
    {
        "timezones": [
            "Asia/Dili"
        ], 
        "latlng": [
            -8.83333333, 
            125.91666666
        ], 
        "name": "Timor-Leste", 
        "capital": "Dili"
    }, 
    {
        "timezones": [
            "Pacific/Tongatapu"
        ], 
        "latlng": [
            -20, 
            -175
        ], 
        "name": "Tonga", 
        "capital": "Nuku'alofa"
    }, 
    {
        "timezones": [
            "America/Port_of_Spain"
        ], 
        "latlng": [
            11, 
            -61
        ], 
        "name": "Trinidad and Tobago", 
        "capital": "Port of Spain"
    }, 
    {
        "timezones": [
            "Africa/Tunis"
        ], 
        "latlng": [
            34, 
            9
        ], 
        "name": "Tunisia", 
        "capital": "Tunis"
    }, 
    {
        "timezones": [
            "Europe/Istanbul"
        ], 
        "latlng": [
            39, 
            35
        ], 
        "name": "Turkey", 
        "capital": "Ankara"
    }, 
    {
        "timezones": [
            "Pacific/Funafuti"
        ], 
        "latlng": [
            -8, 
            178
        ], 
        "name": "Tuvalu", 
        "capital": "Funafuti"
    }, 
    {
        "timezones": [
            "Asia/Taipei"
        ], 
        "latlng": [
            23.5, 
            121
        ], 
        "name": "Taiwan", 
        "capital": "Taipei"
    }, 
    {
        "timezones": [
            "Africa/Dar_es_Salaam"
        ], 
        "latlng": [
            -6, 
            35
        ], 
        "name": "Tanzania", 
        "capital": "Dodoma"
    }, 
    {
        "timezones": [
            "Africa/Kampala"
        ], 
        "latlng": [
            1, 
            32
        ], 
        "name": "Uganda", 
        "capital": "Kampala"
    }, 
    {
        "timezones": [
            "Europe/Kiev", 
            "Europe/Uzhgorod", 
            "Europe/Zaporozhye"
        ], 
        "latlng": [
            49, 
            32
        ], 
        "name": "Ukraine", 
        "capital": "Kiev"
    }, 
    {
        "timezones": [
            "Pacific/Johnston", 
            "Pacific/Midway", 
            "Pacific/Wake"
        ], 
        "latlng": [
            19.2911437, 
            166.618332
        ], 
        "name": "United States Minor Outlying Islands", 
        "capital": null
    }, 
    {
        "timezones": [
            "America/Montevideo"
        ], 
        "latlng": [
            -33, 
            -56
        ], 
        "name": "Uruguay", 
        "capital": "Montevideo"
    }, 
    {
        "timezones": [
            "America/New_York", 
            "America/Detroit", 
            "America/Kentucky/Louisville", 
            "America/Kentucky/Monticello", 
            "America/Indiana/Indianapolis", 
            "America/Indiana/Vincennes", 
            "America/Indiana/Winamac", 
            "America/Indiana/Marengo", 
            "America/Indiana/Petersburg", 
            "America/Indiana/Vevay", 
            "America/Chicago", 
            "America/Indiana/Tell_City", 
            "America/Indiana/Knox", 
            "America/Menominee", 
            "America/North_Dakota/Center", 
            "America/North_Dakota/New_Salem", 
            "America/North_Dakota/Beulah", 
            "America/Denver", 
            "America/Boise", 
            "America/Phoenix", 
            "America/Los_Angeles", 
            "America/Anchorage", 
            "America/Juneau", 
            "America/Sitka", 
            "America/Metlakatla", 
            "America/Yakutat", 
            "America/Nome", 
            "America/Adak", 
            "Pacific/Honolulu"
        ], 
        "latlng": [
            38, 
            -97
        ], 
        "name": "United States", 
        "capital": "Washington D.C."
    }, 
    {
        "timezones": [
            "Asia/Samarkand", 
            "Asia/Tashkent"
        ], 
        "latlng": [
            41, 
            64
        ], 
        "name": "Uzbekistan", 
        "capital": "Tashkent"
    }, 
    {
        "timezones": [
            "Europe/Vatican"
        ], 
        "latlng": [
            41.9, 
            12.45
        ], 
        "name": "Vatican City", 
        "capital": "Vatican City"
    }, 
    {
        "timezones": [
            "America/St_Vincent"
        ], 
        "latlng": [
            13.25, 
            -61.2
        ], 
        "name": "Saint Vincent and the Grenadines", 
        "capital": "Kingstown"
    }, 
    {
        "timezones": [
            "America/Caracas"
        ], 
        "latlng": [
            8, 
            -66
        ], 
        "name": "Venezuela", 
        "capital": "Caracas"
    }, 
    {
        "timezones": [
            "America/Tortola"
        ], 
        "latlng": [
            18.431383, 
            -64.62305
        ], 
        "name": "British Virgin Islands", 
        "capital": "Road Town"
    }, 
    {
        "timezones": [
            "America/St_Thomas"
        ], 
        "latlng": [
            18.35, 
            -64.933333
        ], 
        "name": "United States Virgin Islands", 
        "capital": "Charlotte Amalie"
    }, 
    {
        "timezones": [
            "Asia/Ho_Chi_Minh"
        ], 
        "latlng": [
            16.16666666, 
            107.83333333
        ], 
        "name": "Vietnam", 
        "capital": "Hanoi"
    }, 
    {
        "timezones": [
            "Pacific/Efate"
        ], 
        "latlng": [
            -16, 
            167
        ], 
        "name": "Vanuatu", 
        "capital": "Port Vila"
    }, 
    {
        "timezones": [
            "Pacific/Wallis"
        ], 
        "latlng": [
            -13.3, 
            -176.2
        ], 
        "name": "Wallis and Futuna", 
        "capital": "Mata-Utu"
    }, 
    {
        "timezones": [
            "Pacific/Apia"
        ], 
        "latlng": [
            -13.58333333, 
            -172.33333333
        ], 
        "name": "Samoa", 
        "capital": "Apia"
    }, 
    {
        "timezones": [
            "Asia/Aden"
        ], 
        "latlng": [
            15, 
            48
        ], 
        "name": "Yemen", 
        "capital": "Sana'a"
    }, 
    {
        "timezones": [
            "Africa/Johannesburg"
        ], 
        "latlng": [
            -29, 
            24
        ], 
        "name": "South Africa", 
        "capital": "Pretoria"
    }, 
    {
        "timezones": [
            "Africa/Lusaka"
        ], 
        "latlng": [
            -15, 
            30
        ], 
        "name": "Zambia", 
        "capital": "Lusaka"
    }, 
    {
        "timezones": [
            "Africa/Harare"
        ], 
        "latlng": [
            -20, 
            30
        ], 
        "name": "Zimbabwe", 
        "capital": "Harare"
    }
]